$(document).ready(function () {
  let btns = document.querySelectorAll('.listing-professional .listing-product-button-wrapper');

  if (btns) {
    btns.forEach((btn) =>
      btn.addEventListener('click', function() {
        btn.parentNode.querySelector('.listing-product-description').classList.toggle('active');
      })
    );
  }
});
